[data-sal] {
  transition-delay: 0s;
  transition-delay: var(--sal-delay, 0s);
  transition-duration: .2s;
  transition-duration: var(--sal-duration, .2s);
  transition-timing-function: ease;
  transition-timing-function: var(--sal-easing, ease);
}

[data-sal][data-sal-duration="200"] {
  transition-duration: .2s;
}

[data-sal][data-sal-duration="250"] {
  transition-duration: .25s;
}

[data-sal][data-sal-duration="300"] {
  transition-duration: .3s;
}

[data-sal][data-sal-duration="350"] {
  transition-duration: .35s;
}

[data-sal][data-sal-duration="400"] {
  transition-duration: .4s;
}

[data-sal][data-sal-duration="450"] {
  transition-duration: .45s;
}

[data-sal][data-sal-duration="500"] {
  transition-duration: .5s;
}

[data-sal][data-sal-duration="550"] {
  transition-duration: .55s;
}

[data-sal][data-sal-duration="600"] {
  transition-duration: .6s;
}

[data-sal][data-sal-duration="650"] {
  transition-duration: .65s;
}

[data-sal][data-sal-duration="700"] {
  transition-duration: .7s;
}

[data-sal][data-sal-duration="750"] {
  transition-duration: .75s;
}

[data-sal][data-sal-duration="800"] {
  transition-duration: .8s;
}

[data-sal][data-sal-duration="850"] {
  transition-duration: .85s;
}

[data-sal][data-sal-duration="900"] {
  transition-duration: .9s;
}

[data-sal][data-sal-duration="950"] {
  transition-duration: .95s;
}

[data-sal][data-sal-duration="1000"] {
  transition-duration: 1s;
}

[data-sal][data-sal-duration="1050"] {
  transition-duration: 1.05s;
}

[data-sal][data-sal-duration="1100"] {
  transition-duration: 1.1s;
}

[data-sal][data-sal-duration="1150"] {
  transition-duration: 1.15s;
}

[data-sal][data-sal-duration="1200"] {
  transition-duration: 1.2s;
}

[data-sal][data-sal-duration="1250"] {
  transition-duration: 1.25s;
}

[data-sal][data-sal-duration="1300"] {
  transition-duration: 1.3s;
}

[data-sal][data-sal-duration="1350"] {
  transition-duration: 1.35s;
}

[data-sal][data-sal-duration="1400"] {
  transition-duration: 1.4s;
}

[data-sal][data-sal-duration="1450"] {
  transition-duration: 1.45s;
}

[data-sal][data-sal-duration="1500"] {
  transition-duration: 1.5s;
}

[data-sal][data-sal-duration="1550"] {
  transition-duration: 1.55s;
}

[data-sal][data-sal-duration="1600"] {
  transition-duration: 1.6s;
}

[data-sal][data-sal-duration="1650"] {
  transition-duration: 1.65s;
}

[data-sal][data-sal-duration="1700"] {
  transition-duration: 1.7s;
}

[data-sal][data-sal-duration="1750"] {
  transition-duration: 1.75s;
}

[data-sal][data-sal-duration="1800"] {
  transition-duration: 1.8s;
}

[data-sal][data-sal-duration="1850"] {
  transition-duration: 1.85s;
}

[data-sal][data-sal-duration="1900"] {
  transition-duration: 1.9s;
}

[data-sal][data-sal-duration="1950"] {
  transition-duration: 1.95s;
}

[data-sal][data-sal-duration="2000"] {
  transition-duration: 2s;
}

[data-sal][data-sal-delay="50"] {
  transition-delay: 50ms;
}

[data-sal][data-sal-delay="100"] {
  transition-delay: .1s;
}

[data-sal][data-sal-delay="150"] {
  transition-delay: .15s;
}

[data-sal][data-sal-delay="200"] {
  transition-delay: .2s;
}

[data-sal][data-sal-delay="250"] {
  transition-delay: .25s;
}

[data-sal][data-sal-delay="300"] {
  transition-delay: .3s;
}

[data-sal][data-sal-delay="350"] {
  transition-delay: .35s;
}

[data-sal][data-sal-delay="400"] {
  transition-delay: .4s;
}

[data-sal][data-sal-delay="450"] {
  transition-delay: .45s;
}

[data-sal][data-sal-delay="500"] {
  transition-delay: .5s;
}

[data-sal][data-sal-delay="550"] {
  transition-delay: .55s;
}

[data-sal][data-sal-delay="600"] {
  transition-delay: .6s;
}

[data-sal][data-sal-delay="650"] {
  transition-delay: .65s;
}

[data-sal][data-sal-delay="700"] {
  transition-delay: .7s;
}

[data-sal][data-sal-delay="750"] {
  transition-delay: .75s;
}

[data-sal][data-sal-delay="800"] {
  transition-delay: .8s;
}

[data-sal][data-sal-delay="850"] {
  transition-delay: .85s;
}

[data-sal][data-sal-delay="900"] {
  transition-delay: .9s;
}

[data-sal][data-sal-delay="950"] {
  transition-delay: .95s;
}

[data-sal][data-sal-delay="1000"] {
  transition-delay: 1s;
}

[data-sal][data-sal-easing="linear"] {
  transition-timing-function: linear;
}

[data-sal][data-sal-easing="ease"] {
  transition-timing-function: ease;
}

[data-sal][data-sal-easing="ease-in"] {
  transition-timing-function: ease-in;
}

[data-sal][data-sal-easing="ease-out"] {
  transition-timing-function: ease-out;
}

[data-sal][data-sal-easing="ease-in-out"] {
  transition-timing-function: ease-in-out;
}

[data-sal][data-sal-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
}

[data-sal][data-sal-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(.215, .61, .355, 1);
}

[data-sal][data-sal-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(.645, .045, .355, 1);
}

[data-sal][data-sal-easing="ease-in-circ"] {
  transition-timing-function: cubic-bezier(.6, .04, .98, .335);
}

[data-sal][data-sal-easing="ease-out-circ"] {
  transition-timing-function: cubic-bezier(.075, .82, .165, 1);
}

[data-sal][data-sal-easing="ease-in-out-circ"] {
  transition-timing-function: cubic-bezier(.785, .135, .15, .86);
}

[data-sal][data-sal-easing="ease-in-expo"] {
  transition-timing-function: cubic-bezier(.95, .05, .795, .035);
}

[data-sal][data-sal-easing="ease-out-expo"] {
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

[data-sal][data-sal-easing="ease-in-out-expo"] {
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

[data-sal][data-sal-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-sal][data-sal-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-sal][data-sal-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-sal][data-sal-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(.895, .03, .685, .22);
}

[data-sal][data-sal-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(.165, .84, .44, 1);
}

[data-sal][data-sal-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(.77, 0, .175, 1);
}

[data-sal][data-sal-easing="ease-in-quint"] {
  transition-timing-function: cubic-bezier(.755, .05, .855, .06);
}

[data-sal][data-sal-easing="ease-out-quint"] {
  transition-timing-function: cubic-bezier(.23, 1, .32, 1);
}

[data-sal][data-sal-easing="ease-in-out-quint"] {
  transition-timing-function: cubic-bezier(.86, 0, .07, 1);
}

[data-sal][data-sal-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-sal][data-sal-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-sal][data-sal-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-sal][data-sal-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-sal][data-sal-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-sal][data-sal-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-sal|="fade"] {
  opacity: 0;
  transition-property: opacity;
}

[data-sal|="fade"].sal-animate, body.sal-disabled [data-sal|="fade"] {
  opacity: 1;
}

[data-sal|="slide"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal="slide-up"] {
  transform: translateY(20%);
}

[data-sal="slide-down"] {
  transform: translateY(-20%);
}

[data-sal="slide-left"] {
  transform: translateX(20%);
}

[data-sal="slide-right"] {
  transform: translateX(-20%);
}

[data-sal|="slide"].sal-animate, body.sal-disabled [data-sal|="slide"] {
  opacity: 1;
  transform: none;
}

[data-sal|="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal="zoom-in"] {
  transform: scale(.5);
}

[data-sal="zoom-out"] {
  transform: scale(1.1);
}

[data-sal|="zoom"].sal-animate, body.sal-disabled [data-sal|="zoom"] {
  opacity: 1;
  transform: none;
}

[data-sal|="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-sal="flip-left"] {
  transform: perspective(2000px)rotateY(-91deg);
}

[data-sal="flip-right"] {
  transform: perspective(2000px)rotateY(91deg);
}

[data-sal="flip-up"] {
  transform: perspective(2000px)rotateX(-91deg);
}

[data-sal="flip-down"] {
  transform: perspective(2000px)rotateX(91deg);
}

[data-sal|="flip"].sal-animate, body.sal-disabled [data-sal|="flip"] {
  transform: none;
}

[data-sal] {
  transition-duration: .4s;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #527845;
  --bs-secondary: #395430;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #d8d8d8;
  --bs-dark: #212529;
  --bs-primary-rgb: 82, 120, 69;
  --bs-secondary-rgb: 57, 84, 48;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 216, 216, 216;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #21301c;
  --bs-secondary-text-emphasis: #172213;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #dce4da;
  --bs-secondary-bg-subtle: #d7ddd6;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #bac9b5;
  --bs-secondary-border-subtle: #b0bbac;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: "Source Sans Pro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.4;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: #212529bf;
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: #21252980;
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #527845;
  --bs-link-color-rgb: 82, 120, 69;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #426037;
  --bs-link-hover-color-rgb: 66, 96, 55;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .2rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .2rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 .5rem 1rem #00000026;
  --bs-box-shadow-sm: 0 .125rem .25rem #00000013;
  --bs-box-shadow-lg: 0 1rem 3rem #0000002d;
  --bs-box-shadow-inset: inset 0 1px 2px #00000013;
  --bs-focus-ring-width: .25rem;
  --bs-focus-ring-opacity: .25;
  --bs-focus-ring-color: #52784540;
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: 1rem 0;
}

h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

img, svg {
  vertical-align: middle;
}

[type="submit"] {
  -webkit-appearance: button;
}

[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

iframe {
  border: 0;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-6 {
  margin-left: 50%;
}

@media (width >= 992px) {
  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }
}

@media (width >= 1200px) {
  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.4;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

:not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

:not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #527845;
  --bs-btn-border-color: #527845;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #46663b;
  --bs-btn-hover-border-color: #426037;
  --bs-btn-focus-shadow-rgb: 108, 140, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #426037;
  --bs-btn-active-border-color: #3e5a34;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #527845;
  --bs-btn-disabled-border-color: #527845;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #395430;
  --bs-btn-border-color: #395430;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #304729;
  --bs-btn-hover-border-color: #2e4326;
  --bs-btn-focus-shadow-rgb: 87, 110, 79;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2e4326;
  --bs-btn-active-border-color: #2b3f24;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #395430;
  --bs-btn-disabled-border-color: #395430;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #d8d8d8;
  --bs-btn-border-color: #d8d8d8;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #b8b8b8;
  --bs-btn-hover-border-color: #adadad;
  --bs-btn-focus-shadow-rgb: 184, 184, 184;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #adadad;
  --bs-btn-active-border-color: #a2a2a2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #d8d8d8;
  --bs-btn-disabled-border-color: #d8d8d8;
}

.btn-lg {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: #395430;
  --bs-nav-link-hover-color: #000;
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 .25rem #52784540;
}

.nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.overflow-hidden {
  overflow: hidden !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.start-50 {
  left: 50% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

@media (width >= 768px) {
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
}

@media (width >= 992px) {
  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }
}

@media (width >= 1200px) {
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  src: url("SourceSansPro-Regular.14d85855.woff2");
  font-display: swap;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  src: url("SourceSansPro-SemiBold.daf430f6.woff2");
  font-display: swap;
}

@font-face {
  font-family: Lora;
  font-style: normal;
  font-weight: 400;
  src: url("Lora-Bold.603f4b8d.woff2");
  font-display: block;
}

html {
  font-size: 18px;
}

.display-6 {
  font-size: 1.5rem;
  line-height: 1.25;
}

@media (width >= 768px) {
  .display-6 {
    font-size: 1.6rem;
  }
}

@media (width >= 1080px) {
  .display-6 {
    font-size: 1.8rem;
  }
}

@media (width >= 1220px) {
  .display-6 {
    font-size: 2.2rem;
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-family: Lora, serif;
  line-height: 1.15;
  font-weight: normal !important;
}

hr {
  background: none;
  margin: 40px 0;
}

.btn {
  padding: .5rem 1rem;
}

.btn i {
  vertical-align: text-bottom;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

#logo-container {
  height: 76px;
}

#logo {
  z-index: 2000;
  width: 11rem;
  margin-left: -2.5rem;
  transition: transform .4s;
}

@media (width >= 768px) {
  #logo {
    margin-left: -2rem;
  }
}

#logo small, #logo .small {
  font-size: .75rem;
}

#logo:hover .bg-transform {
  transform: rotate(-1deg)scaleY(1.3);
}

#logo.small {
  transform: scale(.8)translate(-22px, -12px);
}

.zindex-top {
  z-index: 100;
}

.bg-transform {
  z-index: -1;
  transition: transform .4s;
  position: absolute;
  inset: 0;
  transform: rotate(-3deg)scaleY(1.3);
}

.bg-grey {
  color: #fff;
  background: #333;
}

.bg-grey a {
  color: #fff;
}

.bg-grey a:hover {
  color: #fff;
  opacity: .8;
}

.container {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  width: 96vw !important;
}

.container-landingpage {
  max-width: 980px;
}

.lh-1 {
  line-height: 1.1 !important;
}

.image-blur {
  filter: blur(4px);
  transform: scale(1.1);
}

.text-underline {
  border-bottom: 3px solid rgb(var(--bs-light-rgb));
  font-weight: 600;
  display: inline;
  position: relative;
}

.text-white .text-underline {
  border-bottom: 3px solid #fff3;
  font-weight: normal;
}

@media (width >= 768px) {
  .text-underline {
    transform-style: preserve-3d;
    border-bottom: none;
    line-height: 1;
    display: inline-block;
  }

  .text-underline:after {
    content: "";
    background: rgba(var(--bs-light-rgb), .3);
    pointer-events: none;
    position: absolute;
    inset: -5px -6px;
    transform: rotate(.4deg)translateZ(-1px);
  }

  .text-underline:nth-child(2n):after {
    transform: rotate(-.7deg)translateZ(-1px);
  }

  .text-white .text-underline {
    border-bottom: none;
  }

  .text-white .text-underline:after {
    background: rgba(var(--bs-light-rgb), .1);
  }

  .bg-primary .text-underline:after {
    background: #648d55;
  }
}

h1 .text-underline:after, .h1 .text-underline:after {
  inset: -7px -8px;
}

#hilde {
  z-index: 20;
  width: 260px;
  height: 185px;
}

@media (width >= 576px) {
  #hilde {
    width: 390px;
    height: 250px;
  }
}

@media (width >= 768px) {
  #hilde {
    width: 440px;
    height: 300px;
  }
}

@media (width >= 1024px) {
  #hilde {
    width: 500px;
    height: 420px;
  }
}

@media (width >= 1200px) {
  #hilde {
    width: 620px;
    height: 520px;
  }
}

#hilde_background {
  z-index: 5;
  background: #395430;
  border-radius: 300px;
  width: 600px;
  height: 600px;
  display: block;
  position: absolute;
  bottom: 0;
  left: -8%;
  transform: translateY(82%);
}

@media (width >= 576px) {
  #hilde_background {
    border-radius: 500px;
    width: 1000px;
    height: 1000px;
  }
}

@media (width >= 768px) {
  #hilde_background {
    left: 10%;
  }
}

@media (width >= 992px) {
  #hilde_background {
    border-radius: 700px;
    width: 1400px;
    height: 1400px;
    bottom: 0;
    left: -62%;
    transform: translateY(78%);
  }
}

#submit-area .position-absolute {
  width: 250px;
  left: 45% !important;
}

#submit-area .animation-bounce {
  margin-left: 8px;
  animation: 3s cubic-bezier(.45, 0, .55, 1) infinite bounce;
  transform: rotate(-153deg);
}

#submit-area p {
  margin-top: -3px;
  font-size: .9rem;
  line-height: 1.25;
  transform: rotate(3deg);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(-1px);
  }
}

.image-appstore {
  height: 46px;
}

@media (width >= 768px) {
  .image-appstore {
    height: 50px;
  }
}

.image-screenshot {
  width: auto;
  height: 100%;
}

@media (width >= 768px) {
  .image-screenshot {
    height: auto;
  }
}

.image-overlay {
  width: 100%;
}

@media (width <= 768px) {
  .image-outflow {
    margin-left: calc(-.5 * var(--bs-gutter-x));
    margin-right: calc(-.5 * var(--bs-gutter-x));
  }
}

.h-full {
  height: 100%;
}

.step-list {
  counter-reset: step-counter;
  margin: 70px 0 0;
  padding: 0;
  list-style: none;
}

@media (width >= 1023px) {
  .step-list {
    grid-template-columns: 30% 30% 30%;
    gap: 5%;
    display: grid;
  }
}

.step-list li {
  counter-increment: step-counter;
  flex-direction: row;
  margin: 0;
  padding: 0;
  display: flex;
}

@media (width <= 1023px) {
  .step-list li {
    margin-bottom: 30px;
  }
}

.step-list li > span {
  flex: auto;
}

.step-list li:before {
  content: counter(step-counter);
  color: #fff;
  text-align: center;
  background: #527845;
  border-radius: 50%;
  flex: 0 0 42px;
  width: 42px;
  height: 42px;
  margin-top: -7px;
  margin-right: 16px;
  font-weight: bold;
  line-height: 42px;
  display: block;
}

.hilde_mouth {
  animation: 2s cubic-bezier(.45, 0, .55, 1) infinite alternate hilde_mouth;
}

@keyframes hilde_mouth {
  from {
    transform: translate(-6px);
  }

  to {
    transform: translate(5px, -7px);
  }
}

.hilde_tail {
  animation: 2s cubic-bezier(.45, 0, .55, 1) infinite alternate hilde_tail;
}

@keyframes hilde_tail {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1.25deg);
  }
}

@media (width >= 768px) {
  .header-landingpage {
    min-height: 580px;
  }
}

.header-landingpage .container {
  position: relative;
}

@media (width >= 768px) {
  .header-landingpage .display-6 {
    font-size: 2.4rem;
  }
}

.planet {
  background-color: #395430;
  border-radius: 50%;
  display: block;
  position: absolute;
}

.planet-1 {
  opacity: .2;
  width: 100px;
  height: 100px;
  top: 0;
  left: 60%;
}

.planet-2 {
  opacity: .4;
  width: 440px;
  height: 440px;
  top: 20%;
  right: 5%;
}

@media (width <= 1024px) {
  .weltall_wrapper {
    height: 320px;
    margin-top: 20px;
    position: relative;
  }
}

@media (width <= 768px) {
  .weltall_wrapper {
    height: 180px;
    margin-top: 20px;
    position: relative;
  }
}

.kuh_weltall {
  animation: 7.5s cubic-bezier(.45, 0, .55, 1) infinite alternate kuh_weltall;
  position: absolute;
  top: -10%;
  right: 10%;
}

@media (width <= 1280px) {
  .kuh_weltall img {
    width: 220px;
    height: auto;
  }
}

@media (width <= 768px) {
  .kuh_weltall {
    animation: 10s infinite alternate kuh_weltall_mobil;
  }

  .kuh_weltall img {
    width: 120px;
    height: auto;
  }
}

@keyframes kuh_weltall {
  from {
    transform: translate(-16px, 20px)rotate(3deg);
  }

  to {
    transform: translate(15px, -18px)rotate(-1deg);
  }
}

@keyframes kuh_weltall_mobil {
  from {
    transform: translate(-6px, 10px)rotate(2deg);
  }

  to {
    transform: translate(5px, -8px)rotate(-1deg);
  }
}

.planet_light {
  opacity: .4;
  background-color: #d8d8d8;
  border-radius: 50%;
  width: 400px;
  height: 400px;
  display: inline-block;
}

@media (width <= 400px) {
  .planet_light {
    aspect-ratio: 1;
    width: 100%;
    height: 100%;
  }
}

pre {
  white-space: pre-wrap;
}
/*# sourceMappingURL=index.49ba8de9.css.map */
