// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../../node_modules/bootstrap/scss/functions";
@import '../../node_modules/sal.js/dist/sal.css';

[data-sal] {
    transition-duration: 400ms;
}

// 2. Include any default variable overrides here
$enable-dark-mode: false;

$light: #d8d8d8;
$primary: #527845;
$secondary: #395430;
$body-bg: #f2f2f7;

$text-muted: #414141;

$grid-gutter-width: 3rem;

$nav-link-color: #395430;
$nav-link-hover-color: #000000;

$badge-padding-x: 1em;
$badge-padding-y: .5em;
$badge-font-weight: 400;

$line-height-base: 1.4;
$line-height-lg: 1.65;

$border-radius: .2rem;
$border-radius-lg: .2rem;

// $font-size-base: 1.2rem;
$lead-font-weight: 400;
$font-family-sans-serif: "Source Sans Pro", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";

// override of vars
$body-bg: #FFFFFF;

@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/helpers";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/nav";

@import "../../node_modules/bootstrap/scss/forms";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";

// 6. Add additional custom code here
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/SourceSansPro-Regular.woff2");
    font-display: swap;
}
@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/SourceSansPro-SemiBold.woff2");
    font-display: swap;
}
@font-face {
    font-family: "Lora";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/Lora-Bold.woff2");
    font-display: block;
}

html {
    font-size: 18px;

}
.display-6 {
    font-size: 1.5rem;
    line-height: 1.25;

    @media (min-width: 768px) {
        font-size: 1.6rem;
    }
    @media (min-width: 1080px) {
        font-size: 1.8rem;
    }
    @media (min-width: 1220px) {
		font-size: 2.2rem;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.ff-special {
    font-family: 'Lora', serif;
    font-weight: normal !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.15;
}

hr {
    margin: 40px 0;
    background: none;
}
.btn {
    padding: $btn-padding-y-lg $btn-padding-x-lg;

    i {
        vertical-align: text-bottom;
    }
}
.img-fluid {
    @include img-fluid();
}

#logo-container {
    height: 76px;
}
#logo {
    z-index: 2000;
    width: 11rem;
    margin-left: -2.5rem;
    transition: transform 400ms;

    @media (min-width: 768px) {
        margin-left: -2rem;
    }

    small {
        font-size: 0.75rem;
    }

	&:hover {
		.bg-transform {
			transform: rotate(-1deg) scaleY(1.3);
		}
	}

    &.small {
        transform: scale(0.8) translate(-22px, -12px);
    }
}
.zindex-top {
	z-index: 100;
}

.bg-transform {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: rotate(-3deg) scaleY(1.3);
    z-index: -1;

	transition: transform 400ms;
}
.bg-grey {
    background: #333;
    color: #FFF;

    a {
        color: #FFF;

        &:hover {
            color: #FFF;
            opacity: .8;
        }
    }
}

.container {
    @include make-container();
    width: 96vw !important;
    max-width: 1320px;
}

.container-landingpage {
    max-width: 980px;
}

.lh-1 {
    line-height: 1.1 !important;
}

.image-blur {
    filter: blur(4px);
    transform: scale(1.1)
}
.text-underline {
    position: relative;
    display: inline;
    border-bottom: 3px solid rgb(var(--bs-light-rgb));
    font-weight: 600;

    .text-white & {
        font-weight: normal;
        border-bottom: 3px solid rgba(255, 255, 255, .2);
    }

    @media (min-width: 768px) {
        display: inline-block;
        line-height: 1;
        transform-style: preserve-3d;

        border-bottom: none;

        &:after {
            content: '';
            position: absolute;
            top: -5px;
            bottom: -5px;
            left: -6px;
            right: -6px;
            transform: rotate(.4deg) translateZ(-1px);
            background: rgba(var(--bs-light-rgb), 0.3);
            pointer-events: none;
        }

        &:nth-child(even) {
            &:after {
                transform: rotate(-.7deg) translateZ(-1px);
            }
        }

        .text-white & {
            border-bottom: none;
            &:after {
                background: rgba(var(--bs-light-rgb), 0.1);
            }
        }
        .bg-primary & {
            &:after {
                background: #648d55;
            }
        }
    }

}
h1 .text-underline {
    &:after {
        top: -7px;
        bottom: -7px;
        left: -8px;
        right: -8px;
    }
}
.fw-semibold {
    font-weight: 600;
}

#hilde {
    z-index: 20;
    width: 260px;
    height: 185px;

    @media (min-width: 576px) {
        width: 390px;
        height: 250px;
    }
    @media (min-width: 768px) {
        width: 440px;
        height: 300px;
    }
    @media (min-width: 1024px) {
        width: 500px;
        height: 420px;
    }
    @media (min-width: 1200px) {
        width: 620px;
        height: 520px;
    }
}
#hilde_background {
    display: block;
    position: absolute;
    bottom: 0;
    left: -8%;
    width: 600px;
    height: 600px;
    border-radius: 300px;
    background: $secondary;
    transform: translateY(82%);
    z-index: 5;

    @media (min-width: 576px) {
        width: 1000px;
        height: 1000px;
        border-radius: 500px;
    }
    @media (min-width: 768px) {
        left: 10%;
    }
    @media (min-width: 992px) {
        bottom: 0;
        left: -62%;
        width: 1400px;
        height: 1400px;
        border-radius: 700px;
        transform: translateY(78%);
    }
}

#submit-area {
    .position-absolute {
        width: 250px;
        left: 45% !important
    }
    .animation-bounce {
        margin-left: 8px;
        transform: rotate(-153deg); 
        animation: bounce 3000ms cubic-bezier(0.45, 0, 0.55, 1) infinite;
    }
    p {
        font-size: .9rem;
        transform: rotate(3deg);
        line-height: 1.25;
        margin-top: -3px;
    }
    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
        40% {transform: translateY(-5px);}
        60% {transform: translateY(-1px);}
    }

}

.image-appstore {
    height: 46px;
    @media (min-width: 768px) {
        height: 50px;
    }
}
.image-screenshot {
    height: 100%;
    width: auto;
    @media (min-width: 768px) {
        height: auto;
    }
}
.image-overlay {
    width: 100%;
}
.image-outflow {
    @media (max-width: 768px) {
        margin-left: calc(-0.5 * var(--bs-gutter-x));
        margin-right: calc(-0.5 * var(--bs-gutter-x));
    }
}
.h-full {
    height: 100%;
}
.text-shadow {
    text-shadow: 2px 2px 8px rgba(0,0,0,0.2);
}

.step-list {
    @media (min-width: 1023px) {
        display: grid;
        grid-template-columns: 30% 30% 30%;
        gap: 5%;
    }

    margin: 0;
    padding: 0;
    margin-top: 70px;

    list-style: none;
    counter-reset: step-counter;

    li {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;

        @media (max-width: 1023px) {
            margin-bottom: 30px;
        }

        counter-increment: step-counter;

        >span {
            flex: auto;
        }
    }

    li::before {
        content: counter(step-counter);
        background: $primary;
        flex: 0 0 42px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        font-weight: bold;
        border-radius: 50%;
        display: block;
        color: #FFF;
        text-align: center;
        margin-right: 16px;
        margin-top: -7px;
    }
}
.hr-transparent {
    border-color: transparent !important;
}

// animations
.hilde_mouth {
    animation: hilde_mouth 2000ms infinite alternate;
    animation-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
}
@keyframes hilde_mouth {
    from {transform:translate(-6px, 0);}
    to {transform:translate(5px, -7px);}
}

.hilde_tail {
    animation: hilde_tail 2000ms infinite alternate;
    animation-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
}
@keyframes hilde_tail {
    from {transform:rotate(0deg);}
    to {transform:rotate(1.25deg);}
}

.header-landingpage {
    @media (min-width: 768px) {
        min-height: 580px;
    }

    .container {
        position: relative;
    }
    .display-6 {
        @media (min-width: 768px) {
            font-size: 2.4rem;
        }
    }
}
.planet {
    display: block;
    background-color: $secondary;
    position: absolute;
    border-radius: 50%;
}
.planet-1 {
    left: 60%;
    top: 0px;
    width: 100px;
    height: 100px;
    opacity: 0.2;
}
.planet-2 {
    right: 5%;
    top: 20%;
    width: 440px;
    height: 440px;
    opacity: 0.4;
}

.weltall_wrapper {
    @media (max-width: 1024px) {
        position: relative;
        height: 320px;
        margin-top: 20px;
    }
    @media (max-width: 768px) {
        position: relative;
        height: 180px;
        margin-top: 20px;
    }
}
.kuh_weltall {
    position: absolute;
    right: 10%;
    top: -10%;

    animation: kuh_weltall 7500ms infinite alternate;
    animation-timing-function: cubic-bezier(0.45, 0, 0.55, 1);

    @media (max-width: 1280px) {
        img {
            width: 220px;
            height: auto;
        }
    }

    @media (max-width: 768px) {
        animation: kuh_weltall_mobil 10000ms infinite alternate;
        img {
            width: 120px;
            height: auto;
        }
    }
}

@keyframes kuh_weltall {
    from {transform:translate(-16px, 20px) rotate(3deg);}
    to {transform:translate(15px, -18px) rotate(-1deg);}
}
@keyframes kuh_weltall_mobil {
    from {transform:translate(-6px, 10px) rotate(2deg);}
    to {transform:translate(5px, -8px) rotate(-1deg);}
}

.planet_light {
    display: inline-block;
    background-color: $light;
    border-radius: 50%;
    width: 400px;
    height: 400px;
    opacity: .4;

    @media (max-width: 400px) {
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
    }
}

pre {
    white-space: pre-wrap;
}